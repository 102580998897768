<template>
  <v-data-table
    :headers="headers"
    :items="reports"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >
    <template v-slot:top>
      <v-toolbar flat>
        <!-- <v-toolbar-title> -->
        <v-text-field
          v-model="search"
          label="Search"
          single-line
          hide-details
        ></v-text-field>
        <!-- </v-toolbar-title> -->
        <!-- <v-divider class="mx-4" inset vertical></v-divider> -->
        <v-spacer></v-spacer>
      </v-toolbar>
    </template>
    <template v-slot:item.index="{ index }">
      {{ index + 1 }}
    </template>
    <template v-slot:item.amount="{ item }">
      {{item.order_expense - item.transport_expense_price}}
    </template>
    <template v-slot:item.status="{ item }">
      <span style="color:#e8a403;" v-if="item.order_status === 'PT'">รอดำเนินการโอน</span>
      <span style="color:#e8a403;" v-if="item.order_status === 'PC'">รอการตรวจสอบ</span>
      <span style="color:#e8a403;" v-if="item.order_status === 'PP'">อยู่ระหว่างดำเนินการผลิต</span>
      <span style="color:#e8a403;"v-if="item.order_status === 'PTP'">เตรียมจัดส่ง</span>
      <span style="color:green;" v-if="item.order_status === 'F'">ส่งสำเสร็จ</span>
      <span style="color:red;" v-if="item.order_status === 'C'">ยกเลิก</span>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
      >
        {{ icons.mdiPencil }}
      </v-icon>
      <v-icon
        small
        @click="deleteItem(item)"
      >
        {{ icons.mdiDelete }}
      </v-icon>
    </template>
    <template v-slot:no-data>
      <!-- <v-btn
        color="primary"
        @click="initialize"
      >
        Reset
      </v-btn> -->
    </template>
  </v-data-table>
</template>

<script>
import { mdiPencil, mdiDelete } from '@mdi/js'
// import config from '../../../config'
import instance_report from '@/services/report'
import instance_user from '@/services/userManage'

export default {
  setup() {
    return {
      icons: {
        mdiPencil,
        mdiDelete,
      },
    }
  },
  data: () => ({
    reports: [],
    dialog: false,
    dialogDelete: false,
    search: '',
    headers: [
      {
        text: '#',
        value: 'index', sortable: false
      },
      { text: 'รหัสการสั่งซื้อ', value: 'order_inv' , sortable: false},
      { text: 'วันที่สั่งซื้อ', value: 'order_date_time' , sortable: false},
      { text: 'จำนวน', value: 'count_order' , sortable: false},
      { text: 'ค่าสินค้า', value: 'amount', sortable: false },
      { text: 'ค่าส่ง', value: 'transport_expense_price', sortable: false },
      { text: 'ส่วนลด', value: 'order_price_discount', sortable: false },
      { text: 'รวมสุทธิ', value: 'order_expense' , sortable: false},
      { text: 'สถานะ', value: 'status' , sortable: false},
    ],
    desserts: [],
    editedIndex: -1,
    editedItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
    defaultItem: {
      employee_type_name: '',
      employee_type_comment : ''
    },
  }),


  computed: {
    formTitle() {
      return this.editedIndex === -1 ? 'เพิ่ม กลุ่มพนักงาน' : 'แก้ไข กลุ่มพนักงาน'
    },
  },

  watch: {
    // dialog(val) {
    //   val || this.close()
    // },
    // dialogDelete(val) {
    //   val || this.closeDelete()
    // },
  },

  created() {
    // this.initialize()
  },
  mounted() {
    this.getReport()
  },

  methods: {

    editItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialog = true
    },

    deleteItem(item) {
      this.editedIndex = this.reports.indexOf(item)
      this.editedItem = { ...item }
      this.dialogDelete = true
    },
    close() {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = { ...this.defaultItem }
        this.editedIndex = -1
      })
    },

    getReport() {
      this.$store.state.isLoading = true
      var data = {
        search : '',
        date : '',
        status : '',
      }
      instance_user.getBookingOrder(data).then(res => {
        this.reports = res.data.data;
        this.$store.state.isLoading = false
      }).catch(err => {
        this.$store.state.isLoading = false

      });
    },
  },
}
</script>

<style scoped>
hr {
  background-color: #e5e5e5;
  height: 1px;
  border: none;
}
</style>
`
